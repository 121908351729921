$.lui("comp", "#layout_intro", function(selector){
    if(selector.length) {
        setTimeout(function () {
            selector.addClass('mod--hiding')

            setTimeout(function () {
                selector.remove()
            },1000)
        },2500)
    }
})
