$.lui("comp", ".comp_offers", function(selector){
    const navItems = [...selector[0].querySelector('.part_tabs').querySelectorAll('.elm_item[data-id]')];

    if(window.location.hash) {
        const hash = window.location.hash.replace('#','')
        const currentNav = navItems.filter(elm => {
            return elm.dataset.id === hash
        })[0]

        if(currentNav) {
            $(currentNav).anchor_anim(0);
            $(currentNav).click()
        }
    }
});
