$.lui("comp", ".comp_visual", function(selector) {
    let carousel = selector.find("[data-visual-slider]");
    let caption = selector.find('[data-visual-caption]');
    let autoplay = carousel.data("visual-slider");

    $.lib_flickity(function(){
        $.importScript(cdnjs.flickity_sync, function() {
            caption.flickity({
                contain: true,
                pageDots: false,
                prevNextButtons: false,
                pauseAutoPlayOnHover: true
            });

            if (carousel.length) {
                carousel.flickity({
                    imagesLoaded: false,
                    percentPosition: false,
                    setGallerySize: false,
                    autoPlay: autoplay,
                    pageDots: true,
                    wrapAround: false,
                    contain: true,
                    sync: '[data-visual-caption]',
                    prevNextButtons: true,
                    pauseAutoPlayOnHover: false
                });
            }

            let carousel_img = carousel.find('.elm_item .elm_item_image'),
                docStyle = document.documentElement.style,
                transformProp = typeof docStyle.transform === 'string' ? 'transform' : 'WebkitTransform';

            let flkty = carousel.data('flickity');

            carousel.on('scroll.flickity', function () {
                if (win.width() > 1024) {
                    flkty.slides.forEach(function (slide, i) {
                        let img = carousel_img[i],
                            x = (slide.target + flkty.x) * -1/2;

                        img.style[transformProp] = 'translateX(' + x + 'px)';
                    });
                }
            });
        });
    });
});
